<template>
  <div>
    <App-necker />
    <div class="py-48 text-center">找不到頁面</div>
  </div>
</template>

<script>
export default {};
</script>
