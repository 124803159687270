<template>
  <div class="relative bg-gray-600">
    <div class="w-full h-full lg:h-[580px]" v-loading="loading">
      <swiper
        :modules="modules"
        v-if="!loading"
        class="h-full"
        @swiper="onSwiper"
        :style="{
          '--swiper-pagination-color': '#fff',
        }"
        :spaceBetween="0"
        :auto-height="$state.isMobile"
        :thumbs="{ swiper: thumbs }"
        :updateOnImagesReady="true"
        :watchSlidesProgress="true"
      >
        <swiper-slide v-for="row in rows" :key="row.id">
          <div class="w-full h-full bg-gray-600 py-[48px] lg:py-[86px]">
            <div class="container flex lg:flex-row flex-col h-full">
              <div class="w-full lg:w-4/12">
                <p class="text-gray-200 mb-2">{{ row.subtitle }}</p>
                <h4
                  class="
                    text-gray-200
                    font-bold
                    lg:text-[2.5rem]
                    text-3xl
                    mb-6
                    leading-[1.4]
                  "
                >
                  {{ row.title }}
                </h4>
                <p class="text-gray-200 leading-snug">{{ row.content }}</p>
                <ElButton
                  @click="goto(row.link)"
                  class="mt-12 hidden lg:block"
                  type="primary"
                  >線上看展</ElButton
                >
                <!-- <Btn-Enter class="mt-12 hidden lg:block" :url="row.link" /> -->
              </div>
              <div class="w-full lg:w-7/12">
                <div
                  class="
                    flex flex-col
                    lg:h-full
                    w-full
                    items-center
                    justify-center
                    px-10
                    lg:mt-0
                    mt-12
                  "
                >
                  <img
                    class="h-full w-full object-contain"
                    :src="$filters.uploadUrl(row.cover)"
                    :alt="row.title"
                  />
                  <ElButton
                    @click="goto(row.link)"
                    class="mt-12 lg:hidden"
                    type="primary"
                    >線上看展</ElButton
                  >
                  <!-- <Btn-Enter class="mt-12 lg:hidden" :url="row.link" /> -->
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div
      class="
        h-[200px]
        w-full
        lg:w-[200px] lg:h-full lg:absolute lg:top-0 lg:right-0
      "
    >
      <swiper
        v-if="!loading"
        class="h-full w-full"
        @swiper="setThumbs"
        :modules="modules"
        :spaceBetween="0"
        :slidesPerView="$state.isMobile ? 'auto' : 4"
        :direction="$state.isMobile ? 'horizontal' : 'vertical'"
        :updateOnImagesReady="true"
        :watchSlidesProgress="true"
      >
        <swiper-slide
          v-for="row in rows"
          :key="row.title"
          class="!w-2/3 lg:!w-full"
        >
          <div class="relative text-white h-full cursor-pointer">
            <div
              class="
                group
                absolute
                top-0
                right-0
                w-full
                h-full
                px-6
                py-4
                flex flex-wrap
                bg-gray-900
                lg:bg-opacity-70
                bg-opacity-40
                transition-all
                hover:bg-opacity-40
              "
            >
              <h1 class="w-full font-bold">{{ row.title }}</h1>
              <span class="text-xs self-end">精選展覽</span>
              <img
                class="
                  group-hover:translate-x-2 group-hover:-translate-y-2
                  transition-transform
                  transform
                  absolute
                  right-8
                  bottom-5
                "
                src="@/assets/enter.svg"
                alt=""
              />
            </div>

            <img
              class="object-cover w-full h-full"
              :src="$filters.uploadUrl(row.cover, 'sm')"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { ref, reactive } from 'vue';
import { Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import ResourceService from '@/services/Resource';
import { ElButton } from 'element-plus';
import 'swiper/swiper-bundle.min.css';

export default {
  components: {
    Swiper,
    SwiperSlide,
    ElButton,
  },

  setup() {
    let loading = ref(false);
    let rows = reactive([]);
    const fetcher = () => {
      loading.value = true;
      ResourceService.load({
        pid: 1,
        limit: 4,
      }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          rows.push(...res.data);
          loading.value = false;
        }
      });
    };
    fetcher();
    const swiperInstance = ref();
    const onSwiper = (swiper) => {
      swiperInstance.value = swiper;
      setTimeout(() => {
        swiperInstance.value.update();
      }, 1000);
    };
    let thumbs = ref(null);
    const setThumbs = (swiper) => {
      thumbs.value = swiper;
    };
    const goto = (url) => {
      window.location.href = url;
    };
    return {
      goto,
      onSwiper,
      loading,
      rows,
      thumbs,
      setThumbs,
      modules: [Thumbs],
    };
  },
};
</script>
